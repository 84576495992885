<template>
  <div class="message">
    <div class="wrapper">
      <div class="title font-md">
        <span>메시지 발송</span>
      </div>
      <div class="desc font-sm">
        <span v-if="modalParams.members.length">{{ modalParams.members[0].name }}님{{ modalParams.members.length > 1 ? ` 등 ${modalParams.members.length}명에게` : "께" }} 메시지를 발송합니다.</span>
      </div>
      <div class="subject font-md">
        <input type="text" class="form-control border-focus-point font-sm" placeholder="제목을 입력해주세요." ref="titleRef"/>
      </div>
      <div class="content font-sm">
        <textarea class="form-control border-focus-point font-sm" placeholder="내용을 입력해주세요." ref="contentRef"></textarea>
      </div>
    </div>
    <div class="action">
      <button class="btn btn-point btn-block font-sm" @click="send()">
        <span>{{ state.type === "send" ? "보내기" : state.type === "outbox" ? "다시 보내기" : "답장하기" }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import {defineComponent, onMounted, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import env from "@/scripts/env";
import store from "@/scripts/store";
import MemberPicture from "@/components/MemberPicture";
import mixin from "@/scripts/mixin";
import {httpError} from "@/scripts/httpError";
import lib from "@/scripts/lib";

function Component(initialize) {
  this.name = "modalMessageWrite";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  components: {MemberPicture},
  setup() {
    const component = new Component(() => {
      store.commit("tuneModal", {component, size: "sm"});
      state.type = modalParams.type;
    });

    const state = reactive({
      type: "",
    });

    const titleRef = ref();
    const contentRef = ref();
    const modalParams = store.getters.modalParams(component);

    const send = () => {
      const args = {
        title: titleRef.value.value?.trim(),
        content: contentRef.value.value?.trim(),
        takeMemberSequences: modalParams.members.map(m => m.seq)
      };

      if (!args.title) {
        titleRef.value.focus();
        return store.commit("setSwingMessage", "제목을 입력해주세요.");
      } else if (!args.content) {
        contentRef.value.focus();
        return store.commit("setSwingMessage", "내용을 입력해주세요.");
      }

      http.post(`/api/messages/send`, args).then(() => {
        store.commit("closeModal", {
          name: component.name,
          onClose(modal) {
            store.dispatch("callback", {modal});
            store.commit("setSwingMessage", "메시지를 발송하였습니다.");
          }
        });
      }).catch(httpError());
    };

    onMounted(() => {
      if (Array.isArray(modalParams.members) && modalParams.members.length) {
        if (modalParams.members.length > 30) {
          store.commit("closeModal", {name: component.name});
          return store.commit("setSwingMessage", "수신자는 30명을 초과할 수 없습니다.");
        }

        modalParams.members = lib.getRenewed(modalParams.members);
      } else {
        store.commit("closeModal", {name: component.name});
        return store.commit("setSwingMessage", "수신자가 없습니다.");
      }

      env.device === "desktop" && titleRef.value.focus();
    });

    return {component, state, titleRef, contentRef, modalParams, send};
  },
});
</script>

<style lang="scss" scoped>
.message {
  > .wrapper {
    background: #fff;
    padding: $px25;

    .title {
      margin-bottom: $px9;
    }

    .desc {
      color: #666;
      position: relative;
      margin-bottom: $px25;
    }

    .subject {
      margin-bottom: $px15;

      input {
        height: $formHeight;
      }
    }

    .content {
      textarea {
        min-height: $px100;
        padding: $px10 $px12;
      }
    }
  }

  .action {
    .btn {
      padding: $px15;
      height: $px56;
      border-radius: 0;
    }
  }
}
</style>